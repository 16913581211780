// utils/classNameObfuscator.ts

// TODO: Can we randomize the names on every build/run? 

const classNameMap = new Map<string, string>();

const hashCode = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return `class-${Math.abs(hash).toString(36)}`;
};

export const obfuscateClassNames = (classList: string): string => {
  // Split class names by space
  const classes = classList.split(/\s+/);

  // Map each class name to a new hashed class name
  const obfuscatedClasses = classes.map((className) => {
    if (!classNameMap.has(className)) {
      const newClassName = hashCode(className);
      classNameMap.set(className, newClassName);
    }
    return classNameMap.get(className);
  });

  return obfuscatedClasses.join(' ');
};

export const generateDynamicCSS = (): string => {
  let css = '';

  classNameMap.forEach((obfuscatedName, originalName) => {
    css += `.${obfuscatedName} { @apply ${originalName}; }\n`;
  });

  return css;
};
