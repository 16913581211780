import { ApolloClient, InMemoryCache, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// Public GraphQL endpoint
const endpoint = process.env.NEXT_PUBLIC_API_ENDPOINT || 'http://localhost';

// Public GraphQL link with Cache-Control header
const publicLink = new HttpLink({
  uri: `${endpoint}/public/opengraphql`,
  headers: {
    'Cache-Control': 'no-cache'
  }
});

// Authenticated GraphQL link
const authLink = new HttpLink({
  uri: `${endpoint}/graphql`
});

// Middleware to set Cache-Control and authorization headers
const authMiddleware = setContext((_, {
  headers
}) => {
  const token = localStorage.getItem('token'); // Retrieve token from localStorage or other storage mechanism
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'Cache-Control': 'no-cache' // Add Cache-Control header here
    }
  };
});

// Create Apollo Client instance
export const createApolloClient = (authenticated: boolean): ApolloClient<NormalizedCacheObject> => {
  const link: any = authenticated ? authMiddleware.concat(authLink) : publicLink;
  return new ApolloClient({
    link,
    cache: new InMemoryCache()
  });
};